import { Mapper } from '@utils';
import { TutorsDataEntity } from '../entities';
import { TutorModel } from '@models';

export class TutorRepoMapper extends Mapper<TutorsDataEntity, TutorModel[]> {
  mapFrom(param: TutorsDataEntity): any {
    const { data } = param;

    return data.map((item) => ({
      ar_title: item.title,
      en_title: item.title,
      link: `/our-tutors/${item.slug}`,
      slug: item.slug,
      parent: 'our-tutors',
    }));
  }
}
