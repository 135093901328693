import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class BodyStyleService {
  private renderer: Renderer2;
  private appWrapper: HTMLElement;

  constructor(rendererFactory: RendererFactory2,
              @Inject(DOCUMENT) private document: Document) {
    this.renderer = rendererFactory.createRenderer(null, null);
    const wrapper = this.document.getElementById('app-wrapper');
    if (!wrapper) {
      throw new Error('App wrapper element not found!');
    }
    this.appWrapper = wrapper as HTMLElement;
  }

  setOverflowStyle(value: string): void {
    this.renderer.setStyle(this.appWrapper, 'overflow-y', value);
  }
}
