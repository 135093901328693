import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class PageRouteService {
  router: Router = inject(Router);
  storage: StorageService = inject(StorageService);

  get isMawhubPage() {
    return this.router.url === '/curriculums/Mawhoob-Math-Course';
  }
  get isMarket() {
    return this.router.url.includes('/market/');
  }
  get isMarketHome() {
    return (this.router.url.includes('/market') || this.router.url.includes('/en/market')) && !(this.router.url.startsWith('/en/market/registration-flow') && !this.router.url.includes('/result'));
  }
  get isTutorBooking() {
    return this.router.url.startsWith('/tutorbooking') && !this.router.url.startsWith('/tutorbooking/')
  }

  get isAtaaPage() {
    return this.router.url.toLowerCase().startsWith('/learn/ataa');
  }

  get isCurriculumPage() {
    return this.router.url.startsWith('/curriculums');
  }

  get isKelanyPage() {
    return (
      decodeURIComponent(this.router.url) ===
      '/curriculums/دورة-القدرات-مع-أستاذ-محمد-الكيلاني'
    );
  }

  get isLeadMagnet() {
    return this.router.url.includes('/lm') || this.router.url.includes('/en/lm');
  }

  get isExperimentPage() {
    return (this.router.url.includes('/el') || this.router.url.includes('/en/el')) &&
      !this.router.url.includes('el/registration-flow') &&
      !this.router.url.includes('el/qualification-certificate') &&
      !this.router.url.includes('el/step');
  }

  get isAssessmentPage() {
    return this.router.url.includes('/assessment') && (this.router.url.includes('/test')||this.router.url.includes('/result'))
  }
  get isAssessmentTestPage() {
    return this.router.url.includes('/assessment') && this.router.url.includes('/test')
  }

  get isAdvantagesPage() {
    return (
      this.router.url === '/advantages' ||
      this.router.url === '/en/advantages'
    );
  }

  get isJoinUsPage() {
    return this.router.url.includes('oin-as-a-tutor');
  }

  get isHomePage() {
    return (
      this.router.url == '/' ||
      this.router.url.includes('/?') ||
      this.router.url.includes('/#') ||
      this.router.url == '/en' ||
      this.router.url.includes('/en?') ||
      this.router.url.includes('/en#')
    );
  }

  get isWebinar() {
    return (
      this.router.url.startsWith('/webinar') ||
      this.router.url.startsWith('/en/live') ||
      this.router.url.startsWith('/live')
    );
  }

  get isMainHomePage() {
    return (
      this.router.url == '/' ||
      this.router.url.includes('/?') ||
      this.router.url.includes('/#') ||
      this.router.url == '/en' ||
      this.router.url.includes('/en?') ||
      this.router.url.includes('/en#')
    );
  }

  clickHomePage() {
    if (this.storage.getReferralType('TS')) {
      this.navigateTo('/trial-session');
    } else if (this.storage.getReferralType('Advantages-main')) {
      this.navigateTo('/advantages');
    } else if (this.storage.getReferralType('lp/', true)) {
      this.navigateTo('/' + this.storage.getStorageItem('Referral'));
    } else if (this.storage.getReferralType('advantages-sc')) {
      this.navigateTo('/advantages-sc');
    } else if (this.storage.getReferralType('Ataa')) {
      this.navigateTo('/learn/ataa');
    } else if (
      !this.isKelanyPage &&
      !this.isAdvantagesPage &&
      !this.isAtaaPage &&
      !this.isLeadMagnet&&
      !this.isWebinar
    ) {
      this.navigateTo('');
    }
  }

  private navigateTo(route: string) {
    this.router.navigate([route], { queryParamsHandling: 'merge' }).then();
  }
}
