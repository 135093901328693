import { map } from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TutorModel } from '@models';
import { TutorRepoMapper } from './mappers/tutor-repo.mapper';
import { TutorsDataEntity } from './entities';
import { environment } from '../../../environments/environment';
import { TutorRepository } from '@repos';
import {TranslateFacadeService} from "@services";
 import { addAcceptLanguageHeader } from '@helpers';

@Injectable({
  providedIn: 'root',
})
export class TutorImplRepository extends TutorRepository {
  tutorsMapper = new TutorRepoMapper();
  translate = inject(TranslateFacadeService);

  constructor(private http: HttpClient) {
    super();
  }

  getTutors(): Observable<TutorModel[]> {
    return this.http
      .get<TutorsDataEntity>(
        `${environment.apiUrl}/tutorProfiles/website/tutor/pages?locale=${this.translate.currLang}`,{'headers':addAcceptLanguageHeader(this.translate.currLang)}
      )
      .pipe(map(this.tutorsMapper.mapFrom));
  }
}
