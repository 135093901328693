import { Routes } from '@angular/router';

export function filterRoutes(routes: Routes, excludePaths: string[]): Routes {
  const clonedRoutes = [...routes];
  const result = clonedRoutes.reduce<Routes>((filteredRoutes, route) => {
    if (!excludePaths.includes(route.path as string)) {
      if (route.children) {
        route = { ...route, children: filterRoutes(route.children, excludePaths) };
      }
      filteredRoutes.push(route);
    }
    return filteredRoutes;
  }, []);

  return result;
}
