import { lang } from '@enums';

export function getEnumByLang(langCode: lang, enumsMapping: { [key: string]: any }) {
  const enumObject = enumsMapping[langCode];
  if (enumObject) {
    return enumObject;
  } else {
    throw new Error(`Unsupported language code: ${langCode}`);
  }
}
