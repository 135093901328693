export const environment = {
  production: false,
  apiUrl: 'https://qa.algooru.com/api',
  siteUrl: 'https://qasite.algooru.com',
  siteUrl_v2: 'https://performance-qa.algooru.com',
  strapiUrl: 'https://dev-cms.algooru.com/api',
  strapiBaseUrl: 'https://dev-cms.algooru.com',
  segmentKey: 'VhxY13S5hyRUUaod1xxKEsLVPjkJSk6K',
  port: 4002,
  countryCode:'+961',
  checkout_api_Url: 'https://dashbackendqa.algooru.com/api',
  sentryDSN:
    'https://602987d986afb14620ec1dedfd515f8b@o4505860896587776.ingest.sentry.io/4505912369807360',
  recaptcha: {
    siteKey: '6Lfv5lkpAAAAAP6xDx2R95N8QTZhCAhTsVV49LnQ',
  },
  fort_api: "https://sbcheckout.payfort.com/FortAPI/paymentPage",
  messageBirdNumber: '447418310508',
  google_map_key:'AIzaSyCVybfqwOqR6TmCbPghuYgIol2JLuNeo5g',
};
