import {AdvantagesCard, HomePageEntity} from '../entities';
import { Mapper } from '@utils';
import { HomePageModel } from '@models';
import {decompressImage, mapFromFileDataToDomData} from "@helpers";

export class HomeImplRepoMapper extends Mapper<HomePageEntity, HomePageModel> {
  mapFrom(param: HomePageEntity): HomePageModel {
    const {
      data: { attributes },
    } = param;
    // const { introSectioImage: { data: image1Data } } = attributes;
    return {
       ...attributes,
      // introSectioImage:{
      //   thumbnail: image1Data?.attributes?.formats?.thumbnail?.url ?
      //     image1Data?.attributes?.formats?.thumbnail?.url : undefined,
      //   original: image1Data?.attributes?.url||'',
      //   small: image1Data?.attributes?.formats?.small?.url ?
      //     image1Data?.attributes?.formats?.small?.url : undefined,
      //   base64: attributes?.introSectioImage?.compressedBase64 ? decompressImage(attributes.introSectioImage.compressedBase64) : undefined,
      // },
      advantagesCards:attributes.advantagesCards.map(advantage => ({
        ...advantage,
        image:mapFromFileDataToDomData(advantage.image)?.url,
        icon:mapFromFileDataToDomData(advantage.icon)?.url
      })),
      videosTestimonialsVideos:attributes.videosTestimonialsVideos.map(video => ({
        video:mapFromFileDataToDomData(video.video)?.url,
      })),
      imagesTestimonialsImages:attributes.imagesTestimonialsImages.map(image => ({
        image:mapFromFileDataToDomData(image.image)?.url,
      })),
      faqList: attributes.faqList.map((faq) => ({
        ...faq,
        answer: faq.AnswerHtml,
        question: faq.Question,
        answerHtml: faq.AnswerHtml,
      })),
    };
  }
}
