import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { catchError, finalize, throwError } from 'rxjs';
import { LoadingService } from '@services';

let totalRequests = 0;

export const loadingInterceptor: HttpInterceptorFn = (request, next) => {
  const loadingService = inject(LoadingService);

  if (
    !request.headers.get('customLoading') &&
    !request.params.get('noLoading')
  ) {
    totalRequests++;
    loadingService.setLoading(true);
    return next(request).pipe(
      finalize(() => {
        totalRequests--;
        if (totalRequests === 0) loadingService.setLoading(false);
      }),
    );
  } else {
    return next(request).pipe(
      catchError((error: HttpErrorResponse) => {
        loadingService.setLoading(false);
        return throwError(() => error);
      }),
    );
  }
};
