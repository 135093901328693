export const replaceBetweenBrackets = (
  text: string,
  callback: (match: string) => string,
  searchValue?: string,
) => {
  return text.replace(searchValue ?? /{{(.*?)}}/g, (match, p1) => {
    const newText = callback(p1);
    return `${newText}`;
  });
};
