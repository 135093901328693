import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonImplRepository } from './common/common-impl.repository';
import { TutorImplRepository } from './tutor/tutor-impl.repository';
import {
  CommonRepository,
  FeaturedSubjectRepository,
  HomeRepository,
  TutorRepository,
} from '@repos';
import { HomeImplRepository } from './home/home-impl.repository';
import { FeaturedSubjectImplRepository } from './featured-subject/featured-subject-impl.repository';

@NgModule({ imports: [CommonModule], providers: [
        { provide: CommonRepository, useClass: CommonImplRepository },
        { provide: TutorRepository, useClass: TutorImplRepository },
        { provide: HomeRepository, useClass: HomeImplRepository },
        {
            provide: FeaturedSubjectRepository,
            useClass: FeaturedSubjectImplRepository,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class DataModule {}
