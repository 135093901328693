export let arabicPages=[
  'parents', 'blog','webinar', 'educational-resources', 'learn', 'prospect','advantages','advantages-sc', 'curriculums','trial-session','signup'
]

export let englishPages = [
  'join-riyadh-international-tutors','lp/registration-flow'
]
export let simplyHeader = [
  'prospect','step', 'curriculums','thank-you','signup','registration-flow','learn/ataa','tutorbooking','live'
]
export let simplyFooter = [
  'prospect','step', 'curriculums','thank-you','signup','registration-flow'
]
export let hideTopFooterPages = [
  'learn/ataa' ,'tutorbooking'
]

export let hideTopFooterSectionPages = [
  'learn/ataa',
  'our-tutors',
];

export let showHeaderCTAPages = [
  'learn/ataa','tutorbooking','qualification-certificate'
]
export let hideHeaderCTAPages = [
  'tutorbooking/registration-flow'
]
//add the page that you want to have different url in arabic and english here
export let urlBylang:any= {
  // credibility:{
  //   en:'why-algooru',
  //   ar:'ليش-القورو'
  // }
}

export enum pageURLS {
  'HomePage' = 'Home Page',
  'STEP' = 'Form step',
  'Blog' = 'Blog',
  'EducationalResources' = 'Educational Resources',
  'Subjects' = 'Subjects',
  'Tutors' = 'Tutors',
  'Curriculums' = 'Curriculums',
  'AboutUs' = 'About us',
  'Careers' = 'Careers',
  'JoinAsATutor' = 'Join as a tutor',
  'InternationalCurriculumTutors' = 'International Curriculum Tutors',
  'University' = 'University',
  'Partnerships' = 'Partnerships',
  'Parents' = 'Parents',
  'Advantages' = 'Advantages',
  'Webinar' = 'Webinar',
  'LeadMagnet' = 'Lead Magnet',
  'PrivacyPolice' = 'Privacy Police',
  'TermsOfService' = 'Terms of Service',
  'SupportPolicy' = 'Support Policy',
  'OldHomePage' = 'Old Home Page',
  'Prospect' = 'Prospect',
  'ProspectResult' = 'Prospect Result',
  'SignUpResult' = 'Signup Result',
  'Credibility' = 'Credibility',
  'TrialSession' = 'Trial Session'
}
