import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {notSimpleFooter, simpleFooterPages, withoutFooterPages, withoutTopFooterSection} from '@constants';
import {PageRouteService} from './page-route.service';
import {StateService} from '../../tutorbooking/signup-flow/services/state.service';
import {screen} from '../../tutorbooking/signup-flow/misc/screen';
import {breakpoint} from '../enums/breakpoint.enum';
import {TranslateFacadeService} from "./translate-facade.service";
import {UtilityService} from "../../shared/services/utility.service";

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  router: Router = inject(Router);
  StateService: StateService = inject(StateService);
  TranslateFacadeService: TranslateFacadeService = inject(TranslateFacadeService);
  utility: UtilityService = inject(UtilityService);

  screen = screen;
  pageRoute: PageRouteService = inject(PageRouteService);

  get renderSimpleFooter() {
    const isSimpleFooterPage = this.isPageIncluded(simpleFooterPages);
    const isNotExcludedPage = !this.isPageIncluded(notSimpleFooter);
    return isSimpleFooterPage && isNotExcludedPage;
  }

  get renderFooter() {
    return !this.isPageIncluded(withoutFooterPages);
  }

  get renderFooterUrls() {
    return !this.pageRoute.isTutorBooking;
  }

  get withoutFooterLists() {
    return this.isPageIncluded(withoutTopFooterSection);
  }

  get withoutFooterBorder() {
    return this.pageRoute.isMawhubPage ||
      this.pageRoute.isKelanyPage ||
      this.pageRoute.isAdvantagesPage ||
      this.pageRoute.isLeadMagnet ||
      this.pageRoute.isTutorBooking ||
      this.pageRoute.isExperimentPage ||
      this.pageRoute.isAssessmentPage ||
      this.pageRoute.isMarketHome ||
      this.utility.isWebinar ||
      this.withoutFooterLists;
  }

  get withoutFooterUrls() {
    return this.pageRoute.isExperimentPage || this.pageRoute.isTutorBooking || this.pageRoute.isMarket || this.utility.isWebinar || this.utility.isLeadMagnet;
  }

  get renderFooterTopSection() {
    return (!this.pageRoute.isMawhubPage &&
      !this.pageRoute.isKelanyPage &&
      !this.pageRoute.isAdvantagesPage &&
      !this.pageRoute.isLeadMagnet &&
      !this.pageRoute.isTutorBooking &&
      !this.pageRoute.isExperimentPage &&
      !this.pageRoute.isAssessmentPage &&
      !this.pageRoute.isMarket &&
      !this.utility.isWebinar &&
      !this.withoutFooterLists);
  }

  get headerWithoutLink() {
    return this.pageRoute.isHomePage
  }

  get renderFixedHeader() {
    return (
        this.router.url.startsWith('/advantages') ||
        this.pageRoute.isHomePage ||
        this.router.url.startsWith('/learn') ||
        this.router.url.startsWith('/el') ||
        this.utility.isMarketPage ||
        this.utility.isWebinar ||
        this.utility.isPackagesV2 ||
        this.router.url.startsWith('/en/el') ||
        this.router.url.startsWith('/testimonials') ||
        this.router.url.startsWith('/en/testimonials'))
      && !this.router.url.startsWith('/advantages-sc/step');
  }

  get renderCertificate() {
    return !this.router.url.includes('el/') && !this.pageRoute.isMarket  && !this.utility.isWebinar && !this.utility.isLeadMagnet;
  }

  hideSimpleFooter() {
    return this.StateService.selectedScreen == screen.packages;
  }

  getCurrPage(page: string) {
    return this.router.url.match(page);
  }

  isPageIncluded(pageList: string[]) {
    return pageList.some((page) => this.getCurrPage(page));
  }

  biggerThan(size: breakpoint): any {
    if (this.utility.isBrowser) {
      return window.screen.width > size;
    }
  }

  smallerThan(size: breakpoint): any {
    if (this.utility.isBrowser) {
      return window.screen.width <= size;
    }
  }

  isRTL() {
    return this.TranslateFacadeService.currLang == 'ar'
  }
}
