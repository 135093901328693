import { forkJoin, map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateResourceInterface } from '@common-dto';
import { mergeObjectsRecursively } from '@helpers';

export class MultiTranslateHttpLoader implements TranslateLoader {
  resources: TranslateResourceInterface[];
  withCommon: boolean;

  constructor(
    private readonly http: HttpClient,
    {
      resources,
      withCommon = true,
    }: { resources: TranslateResourceInterface[]; withCommon?: boolean },
  ) {
    this.resources = resources;
    this.withCommon = withCommon;
  }

  getTranslation(lang: string): Observable<Record<string, unknown>> {
    let resources: TranslateResourceInterface[] = [...this.resources];

    if (this.withCommon) {
      resources = [
        { prefix: './assets/i18n/common/', suffix: '.json' },
        ...resources,
      ];
    }

    return forkJoin(
      resources.map((config: TranslateResourceInterface) => {
        return this.http.get<Record<string, unknown>>(
          `${config.prefix}${lang}${config.suffix}`,
        );
      }),
    ).pipe(
      map((response: Record<string, unknown>[]) =>
        mergeObjectsRecursively(response),
      ),
    );
  }
}
