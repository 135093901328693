export enum MetaTag {
  DESCRIPTION = 'description',
  OG_LOCALE = 'og:locale',
  OG_TITLE = 'og:title',
  OG_URL = 'og:url',
  OG_DESCRIPTION = 'og:description',
  OG_IMAGE = 'og:image',
  TWITTER_TITLE = 'twitter:title',
  TWITTER_DESCRIPTION = 'twitter:description',
}
