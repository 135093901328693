export enum metaTag {
  'description' = 'description',
  'ogLocale' = 'og:locale',
  'ogTitle' = 'og:title',
  'ogURL' = 'og:url',
  'ogDescription' = 'og:description',
  'ogImage' = 'og:image',
  'twitterTitle' = 'twitter:title',
  'twitterDescription' = 'twitter:description',
}
