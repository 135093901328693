import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GeoIpService {


  constructor(public http: HttpClient) {
  }

  geoIP() {
    return this.http.get(`${environment.apiUrl}/geoIP?noLoading=true`, {
      headers: { 'customLoading': 'true' },
    });
  }
}
