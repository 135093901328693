import { inject, Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { GTMService } from './google-tag-manager.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SEOService } from './seo.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  arabicPages,
  englishPages,
  hideHeaderCTAPages,
  hideTopFooterPages,
  hideTopFooterSectionPages,
  showHeaderCTAPages,
  simplyFooter,
  simplyHeader,
  urlBylang,
} from '../constants/global';
import { LoadingService } from './loading.service';
import { PageRouteService, TranslateFacadeService } from '@services';
import { StateService } from '../../tutorbooking/signup-flow/services/state.service';
import { screen } from '../../tutorbooking/signup-flow/misc/screen';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';


declare var window: any;

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  localizeRouterService: LocalizeRouterService = inject(LocalizeRouterService);
  public route: Router;
  public activatedRoute: ActivatedRoute;
  public translate: TranslateService;
  public SEOService: SEOService;
  public GTMService: GTMService;
  public httpClient: HttpClient;
  public sanitizer: DomSanitizer;
  public StateService: StateService;
  public LoadingService: LoadingService;
  public translateFacadeService: TranslateFacadeService;
  public referral: BehaviorSubject<string> = new BehaviorSubject<string>('');
  changedirection$ = new BehaviorSubject('rtl');
  languageCode = '';
  changeLang$ = new Subject();
  selectedPage: string | undefined;
  haveOtherLang: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  expRef: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  PageRouteService: PageRouteService = inject(PageRouteService);
  screen = screen;
  private thankYouPageSource = '';
  private pageName = new BehaviorSubject('');

  constructor(injector: Injector, @Inject(PLATFORM_ID) private platformId: string, @Inject(DOCUMENT) private document: any, private location: Location) {
    this.route = injector.get(Router);
    this.GTMService = injector.get(GTMService);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.translate = injector.get(TranslateService);
    this.SEOService = injector.get(SEOService);
    this.LoadingService = injector.get(LoadingService);
    this.StateService = injector.get(StateService);
    this.translateFacadeService = injector.get(TranslateFacadeService);
    this.httpClient = injector.get(HttpClient);
    this.sanitizer = injector.get(DomSanitizer);
    this.translate.setDefaultLang('ar');
    this.selectedPage = this.route.url;
    this.route.events.subscribe(item => {
      if (item instanceof NavigationEnd) {
        this.selectedPage = item.url;
      }
    });
  }

  get referralUrl(): any {
    if (this.isBrowser) {
      if (sessionStorage.getItem('ReferralUrl')) {
        return sessionStorage.getItem('ReferralUrl')?.toString();
      } else {
        return decodeURIComponent(window.location.origin + this.referral.value);
      }
    } else {
      return '';
    }
  }

  get fullLanguageCode() {
    return this.translateFacadeService.currLang;

  }

  get getPageName() {
    return this.pageName;
  }

  get addCustomLoadingHeader(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('customLoading', 'true');
    return headers;
  }

  get filterRoute() {
    return this.route.url.split('/').map(str => str.replace(/\?.*/, '')).filter(n => n && n != 'en');
  }

  get isFixedHeader() {
    return (this.route.url.startsWith('/advantages') || (this.route.url.startsWith('/tutorbooking') && !this.route.url.startsWith('/tutorbooking/')) || this.isHomePageV2 || this.route.url.startsWith('/learn') || this.route.url.startsWith('/el') || this.route.url.startsWith('/en/el') || this.route.url.startsWith('/testimonials') || this.route.url.startsWith('/en/testimonials')) && !this.route.url.startsWith('/advantages-sc/step') || this.isWebinarWithoutResult;
  }

  get isMawhubPage() {
    return this.route.url === '/curriculums/Mawhoob-Math-Course';
  }

  get isAtaaPage() {
    return this.route.url.toLowerCase().startsWith('/learn/ataa');
  }

  get isKelanyPage() {
    return decodeURIComponent(this.route.url) === '/curriculums/دورة-القدرات-مع-أستاذ-محمد-الكيلاني';
  }

  get isAdvantagesPage() {
    return this.route.url === '/advantages' || this.route.url === '/en/advantages' || this.route.url.startsWith('/advantages?') || this.route.url.startsWith('/en/advantages?') || (this.route.url.includes('/lp/') && !this.route.url.includes('registration-flow'));
  }



  get isWebinar() {
    return this.route.url.startsWith('/webinar') || this.route.url.startsWith('/en/live') || this.route.url.startsWith('/live');
  }
  get isWebinarWithoutResult() {
    return (this.route.url.startsWith('/webinar') || this.route.url.startsWith('/en/live') || this.route.url.startsWith('/live')) && !this.route.url.includes('/result');
  }
  get isAdvantages() {
    return this.route.url == ('/advantages') || this.route.url == ('/en/advantages');
  }

  get isLeadMagnet() {
    return this.route.url.includes('/lm') || this.route.url.includes('/en/lm');
  }

  get isExperimentPage() {
    return (this.route.url.includes('/el') || this.route.url.includes('/en/el')) && !this.route.url.includes('el/registration-flow') && !this.route.url.includes('el/step');
  }

  get isQualificationPage() {
    return this.route.url.includes('/qualification-certificate') || this.route.url.includes('/en/qualification-certificate');
  }

  get isMainHomePage() {
    return this.route.url == '/' || this.route.url.includes('/?') || this.route.url.includes('/#') || this.route.url == '/en' || this.route.url.includes('/en?') || this.route.url.includes('/en#');
  }

  get showFooter() {
    return !['signup'].some(el => {
      return this.route.url.match(el);
    });
  }

  get isTutorDetails() {
    return !this.route.url.startsWith('/tutor');
  }

  get showHeaderCTAPages() {
    return (showHeaderCTAPages.some(el => {
      return this.route.url.toLowerCase().match(el);
    }) && !hideHeaderCTAPages.some(el => {
      return this.route.url.toLowerCase().match(el);
    }));
  }

  get hideTopFooter() {
    return hideTopFooterPages.some(el => {
      return this.route.url.toLowerCase().match(el);
    });
  }

  get hideTopFooterSection() {
    return hideTopFooterSectionPages.some((el) => {
      return this.route.url.toLowerCase().match(el);
    });
  }

  get isCurriculumPage() {
    return this.route.url.startsWith('/curriculums');
  }

  get fullLanguage() {
    return this.languageCode == 'en' ? 'English' : 'Arabic';
  }

  get languageHeaders() {
    return this.languageCode == 'en' ? 'en' : 'ar';
  }

  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  get thankYouSource() {
    return this.thankYouPageSource;
  }

  set thankYouSource(source) {
    this.thankYouPageSource = source;
  }

  get webURL() {
    //return this.document.location.origin
    return environment.siteUrl;
  }

  get fullWebURL() {
    return decodeURIComponent(this.document.location.href);
  }

  get gclid() {
    let gclid: any;
    if (this.isBrowser) {
      gclid = localStorage.getItem('gclid');
    }
    return JSON.parse(gclid)?.value;
  }

  get isRtl() {
    return this.translateFacadeService.currLang == 'ar';
  }

  get languageRoute() {
    return this.isRtl ? '' : 'en/';
  }

  get isHomePageV2() {
    return this.route.url == '/' || this.route.url.includes('/?') || this.route.url.includes('/#') || this.route.url == '/en' || this.route.url.includes('/en?') || this.route.url.includes('/en#');
  }

  get isHomePage() {
    return this.route.url.includes('/trial-session') || this.route.url.startsWith('/advantages') || this.route.url.startsWith('/advantages-sc')  || this.route.url == '/' || this.route.url.includes('/?') || this.route.url.includes('/#') || this.route.url == '/en' || this.route.url.includes('/en?') || this.route.url.includes('/en#');
  }

  get isTutorbooking() {
    return this.route.url.startsWith('/tutorbooking') && !this.route.url.startsWith('/tutorbooking/');
  }
  get isMarketPage() {
    return (this.route.url.includes('/market') || this.route.url.includes('/en/market')) && !this.route.url.includes('market/registration-flow') && !this.route.url.includes('market/step');
  }

  get isMarketPageWithFlow() {
    return this.route.url.includes('/market/');
  }
  get isSimplyFooter() {
    return this.isSimplyFooterPages() && !this.route.url.match('registration-flow/result') && !this.route.url.match('trial-session/thank-you') && !this.route.url.match('curriculums');
  }

  get isMobile() {
    if (isPlatformBrowser(this.platformId)) {
      return window.screen.width < 1024;
    } else {
      return false;
    }
  }

  get checkMobile() {
    let ua;
    if (this.isBrowser) ua = navigator.userAgent;
    if (ua && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
      return true;
    else
      return false;
  }


  get seqmentPageName() {
    let OriginalPageName = this.route.url.includes('?') ? this.route.url.substring(0, this.route.url.indexOf('?')) : this.route.url;
    let pageName = OriginalPageName === '/' ? 'home page v1.0' : OriginalPageName === '/en' ? 'home page v1.0' : OriginalPageName.substring(1);
    return decodeURI(pageName);
  }

  get hideWhatsappButton() {
    return ['result'].some(el => {
      return this.route.url.match(el);
    });
  }

  get hideWhatsapp() {
    return ['registration-flow'].some(el => {
      return this.route.url.match(el);
    });
  }

  numberConverter(number: any) {
    return number.toString().replace(/[٠-٩]/g, (d: any) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d));
  }

  setReferralUrl(value: string) {
    this.referral.next(value);
    if (this.isBrowser) {
      sessionStorage.setItem('ReferralUrl', decodeURIComponent(window.location.origin + this.referral.value));
    }
  }

  hideMessageBirdChatWidget() {
    let counter = 0;
    let refreshId = setInterval(() => {
      counter++;
      const el = document.getElementById('live-chat-widget');
      if (this.route.url.startsWith('/curriculums') || this.route.url.includes('registration-flow') || this.route.url.startsWith('/step') || this.route.url.startsWith('/trial-session/step') || this.route.url.startsWith('/advantages-sc/step') ||  this.route.url.startsWith('/become-a-partner/signup') || this.route.url.includes('checkout') || this.route.url.includes('payment-result')) {
        el ? el.style.visibility = 'hidden' : null;
      } else {
        el ? el.style.visibility = 'visible' : null;
      }
      if (el || counter == 100) {
        clearInterval(refreshId);
      }
    }, 100);
  }

  sortByOrder(items: any, key = 'order'): any {
    return items.sort((a: any, b: any) => a[key] - b[key]);
  }

  gotToNotFound() {
    this.LoadingService.set404Loading(true);
    if (this.isBrowser) {
      location.href = '/404' + this.route.url;
    }
  }

  setPageName(pageName: any) {
    this.pageName.next(pageName);
  }

  clickHomePage() {
    if (this.isTrialSessionReferral()) {
      this.route.navigate([this.localizeRouterService.translateRoute('/trial-session')], { queryParamsHandling: 'merge' });
    } else if (this.isAdvantagesReferral()) {
      this.route.navigate([this.localizeRouterService.translateRoute('/advantages')], { queryParamsHandling: 'merge' }); //
    } else if (this.isAdvantagesSCReferral()) {
      this.route.navigate(['/advantages-sc'], { queryParamsHandling: 'merge' });
    } else if (this.isAtaaReferral()) {
      this.route.navigate([this.localizeRouterService.translateRoute('/learn/ataa')], { queryParamsHandling: 'merge' }); //
    } else if (this.isTutorbookingReferral()) {
      this.route.navigate(['/tutorbooking'], { queryParamsHandling: 'merge' });
    } else if (this.isExperimentReferral()) {
      let urlSeqment = sessionStorage.getItem('Referral')?.split('/') || []
      this.route.navigate([this.localizeRouterService.translateRoute('/'),...urlSeqment]);
    } else if (this.isMarketReferral()) {
      let urlSeqment = sessionStorage.getItem('Referral')?.split('/') || []
      this.route.navigate([...urlSeqment]);
    } else if (this.isWebinarReferral()) {
      let urlSeqment = sessionStorage.getItem('Referral')?.split('/') || []
      this.route.navigate([...urlSeqment]);
    } else {
      if (!this.isKelanyPage && !this.isAdvantagesPage && !this.isAtaaPage && !this.isLeadMagnet && !this.isExperimentPage) {
        this.route.navigate([this.localizeRouterService.translateRoute('/')], { queryParamsHandling: 'merge' });
      }
    }
  }

  getEducationalResources(): Observable<any> {
    return this.httpClient.get('https://algoorubitbucket.s3.me-south-1.amazonaws.com/Json+Files/rducational-resources.json');
  }

  getQueryParam(queryParam?: any) {
    let param = '';
    let allQ = { ...this.activatedRoute.snapshot.queryParams, ...queryParam };
    if (Object.keys(allQ).length > 0) {
      const paramString = Object.keys(allQ)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(allQ[key]))
        .join('&');

      param = '?' + paramString;
    }
    return param;
  }

  isUrlBylang(url: string) {
    let hasValue = false;
    Object.values(urlBylang).forEach((value: any) => {
      if (Object.values(value).includes(url.replace('/', ''))) {
        hasValue = true;
      }
    });
    return hasValue;
  }

  isUrlBylangEnglish(url: string) {
    let hasValue = false;
    Object.values(urlBylang).forEach((value: any) => {
      if (value.en == url.replace('/', '')) {
        hasValue = true;
      }
    });
    return hasValue;
  }

  isUrlBylangArabic(url: string) {
    let hasValue = false;
    Object.values(urlBylang).forEach((value: any) => {
      if (value.ar == url.replace('/', '')) {
        hasValue = true;
      }
    });
    return hasValue;
  }

  getUrlByLang(url: string) {
    let haveUrlByLang = urlBylang.hasOwnProperty(url);
    if (haveUrlByLang) {
      return this.isRtl ? urlBylang[url].ar : urlBylang[url].en;
    } else {
      return url;
    }
  }

  changeDirection() {
    if (this.isRtl) {
      this.changedirection$.next('ltr');
      this.languageCode = 'en';
    } else {
      this.changedirection$.next('rtl');
      this.languageCode = '';
    }

    this.changeLang$.next(this.translateFacadeService.currLang);
    if (this.isUrlBylang(decodeURI(this.filterRoute[0]))) {
      this.route.navigate([this.getCounterPartUrlBylang(decodeURI(this.filterRoute[0]))], {
        queryParams: this.activatedRoute.snapshot.queryParams,
        queryParamsHandling: 'merge',
      });
    }
    if (this.isArabicOnlyByValue(this.route.url) && !this.isEnglishOnlyByValue(this.route.url)) {
      this.route.navigate([this.languageCode], { queryParamsHandling: 'merge' });
    } else {
      this.route.navigate([this.languageCode, ...this.filterRoute.map(el => decodeURI(el))], {
        queryParams: this.activatedRoute.snapshot.queryParams,
        queryParamsHandling: 'merge',
      });
    }
    this.changeLang();
  }

  getCounterPartUrlBylang(value: any) {
    for (const key in urlBylang) {
      if (urlBylang.hasOwnProperty(key)) {
        const mapping = urlBylang[key];
        if (Object.values(mapping).includes(value)) {
          if (this.isRtl) {
            return mapping.ar;
          } else {
            return mapping.en;
          }
        }
      }
    }
    return null;
  }

  routeUrl(route?: any, queryParam?: any) {
    if (route) {
      if (this.isArabicOnlyByValue(route) || this.languageCode != 'en') {
        return this.webURL + '/' + route + this.getQueryParam(queryParam);
      } else {
        return this.webURL + '/' + this.languageCode + '/' + route + this.getQueryParam(queryParam);
      }
    } else {
      if (this.isFixedHeader) {
        return this.webURL + '/advantages' + this.getQueryParam(queryParam);
      } else {
        return this.webURL + '/' + this.languageCode + this.getQueryParam(queryParam);
      }
    }
  }

  isSimplyFooterPages() {
    return simplyFooter.some(el => {
      return this.route.url.match(el);
    });
  }

  isSimplyHeader(whatsapp: boolean = false) {
    if (whatsapp) {
      return ['prospect', 'thank-you', 'curriculums', 'signup', 'registration-flow'].some(el => {
        return this.route.url.match(el);
      });
    } else {
      return simplyHeader.some(el => {
        return this.route.url.toLowerCase().match(el);
      }) || this.isTutorbookingReferral() || this.PageRouteService.isAssessmentTestPage;
    }
  }

  elWhatsappMessage() {
    let message: any;
    if (this.isRtl) {
      message = 'مرحباً فريق القورو 👋';
    } else {
      message = 'مرحباً فريق القورو 👋';
    }
    if (this.checkMobile) {
      window.open(
        `whatsapp://send?phone=${environment.messageBirdNumber}&text=` + message,
        '_blank',
      );
    } else {
      window.open(
        `https://web.whatsapp.com/send?phone=${environment.messageBirdNumber}&text=` + message,
        '_blank',
      );
    }
    return message;
  }

  numberConverterE2A(number: any) {
    return number.toString().replace(/\d/g, (d: any) => '٠١٢٣٤٥٦٧٨٩'[d]);
  }

  lpWhatsappMessage() {
    let message: any;
    if (this.isRtl) {
      message = 'أهلًا القورو 👋';
    } else {
      message = 'أهلًا القورو 👋';
    }
    if (this.checkMobile) {
      window.open(
        `whatsapp://send?phone=${environment.messageBirdNumber}&text=` + message,
        '_blank',
      );
    } else {
      window.open(
        `https://web.whatsapp.com/send?phone=${environment.messageBirdNumber}&text=` + message,
        '_blank',
      );
    }
    return message;
  }

  isNewSignUp() {
    return ['registration-flow', 'curriculums'].some(el => {
      return this.route.url.match(el);
    }) && !this.route.url.match('tutorbooking/registration-flow');
  }

  isArabicOnlyByValue(value: string) {
    return arabicPages.some(el => {
      return value.split('?')[0].match(el) || decodeURIComponent(value).split('?')[0].match(el);
    });
  }

  isEnglishOnlyByValue(value: string) {
    return englishPages.some(el => {
      return value.split('?')[0].match(el);
    });
  }

  changeLang() {
    if (this.isRtl) {
      this.translate.use('ar');
    } else {
      this.translate.use('en');
    }
    this.changeTitle('appName');
  }

  changeTitle(title: string | string[]) {
    this.translate.get(title).subscribe(el => {
      this.SEOService.setTitle(el);
    });
  }

  isWider(width: number, wider: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      return wider ? window.screen.width > width : window.screen.width < width;
    } else {
      return false;
    }
  }

  pageViewEvent(page_name: string) {
    // this.segmentService.track('Website Page view', {pageName: page_name})
    // this.GTMService.pageViewEvent(this.route.url, page_name, this.isRtl)
  }

  roundPrice(num: any) {
    return Math.ceil(num * 100) / 100;
  }

  fixFloatNum(num: number | string) {
    if (num)
      return Number(parseFloat(num.toString()).toFixed(2));

    return 0;
  }

  fixPriceAmount(num: any) {
    if (Number.isInteger(num * 10)) {
      return num;
    } else {
      return (Math.floor(num * 100) / 100).toFixed(2);
    }
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  isSelectedSubject(level: any, clas: any) {
    let isER = this.route.url.split('/').findIndex(el => {
      return el.match('educational-resources');
    });
    if (isER > -1) {

      return this.route.url.split('/')[isER + 1]?.replace(/\?.*$/, '') == level.id && this.route.url.split('/')[isER + 2]?.replace(/\?.*$/, '') == clas.id;
    } else {
      return false;
    }
  }

  isSelectedTutorsPage(clas: any) {
    let url = this.route.url.replace(/^en\//, '')?.replace(/\?.*/, '')
    let isER = url.split('/').findIndex(el => {
      return el.match('our-tutors');
    });
    if (isER > -1) {
      return decodeURI(url.split('/')[isER + 1]) == clas;
    } else {
      return false;
    }
  }

  isSelectedLevel(level: any) {
    let isER = this.route.url.split('/').findIndex(el => {
      return el.match('educational-resources');
    });
    if (isER > -1) {
      return this.route.url.split('/')[isER + 1]?.replace(/\?.*$/, '') == level.id;
    } else {
      return false;
    }
  }

  isSelectedSubjectPage(clas: any) {
    let isER = this.route.url.split('/').findIndex(el => {
      return el.match('learn');
    });
    if (isER > -1) {
      return decodeURI(this.route.url.split('/')[isER + 1])?.replace(/\?.*$/, '') == clas;
    } else {
      return false;
    }
  }

  setReferral(referral: string = 'Main') {
    if (this.isBrowser) {
      sessionStorage.setItem('Referral', referral);
    }
  }

  clearTrialSessionReferral() {
    if (this.isBrowser) {
      sessionStorage.removeItem('Referral');
    }
  }

  isTrialSessionReferral() {
    if (this.isBrowser) {
      return sessionStorage.getItem('Referral') == 'TS';
    } else {
      return undefined;
    }
  }

  isTutorbookingReferral() {
    if (this.isBrowser) {
      return sessionStorage.getItem('Referral') == 'tutorbooking' || (sessionStorage.getItem('ReferralUrl')?.split('/').splice(3).join('/').includes('tutorbooking') && !this.isHomePageV2);
    } else {
      return undefined;
    }
  }


  isAdvantagesReferral() {
    if (this.isBrowser) {
      return sessionStorage.getItem('Referral') == 'Advantages-main';
    } else {
      return undefined;
    }
  }

  isAdvantagesSCReferral() {
    if (this.isBrowser) {
      return sessionStorage.getItem('Referral') == 'advantages-sc';
    } else {
      return undefined;
    }
  }


  isAtaaReferral() {
    if (this.isBrowser) {
      return sessionStorage.getItem('Referral') == 'Ataa';
    } else {
      return undefined;
    }
  }

  isExperimentReferral() {
    if (this.isBrowser) {
      return sessionStorage.getItem('Referral')?.includes('el/');
    } else {
      return undefined;
    }
  }
  isMarketReferral() {
    if (this.isBrowser) {
      return sessionStorage.getItem('Referral')?.includes('market/');
    } else {
      return undefined;
    }
  }
  isWebinarReferral() {
    if (this.isBrowser) {
      return sessionStorage.getItem('Referral')?.includes('live/');
    } else {
      return undefined;
    }
  }
  goToSignup() {

  }
}
