export const mergeObjectsRecursively = (
  objects: Record<string, unknown>[],
): Record<string, unknown> => {
  const mergedObject: Record<string, unknown> = {};

  for (const obj of objects) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          mergedObject[key] = mergeObjectsRecursively([
            mergedObject[key] as Record<string, unknown>,
            obj[key] as Record<string, unknown>,
          ]);
        } else {
          mergedObject[key] = obj[key];
        }
      }
    }
  }

  return mergedObject;
};
