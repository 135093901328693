import { DomFileDataInterface, FileDataInterface } from '@common-dto';

export const mapFromFileDataToDomData = (
  file?: FileDataInterface,
): DomFileDataInterface => {
  return {
    alt: file?.data?.attributes?.name,
    url: file?.data?.attributes?.url,
  };
};
