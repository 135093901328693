import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  private audio: HTMLAudioElement;
  private isPlaying = false;

  constructor() {
    this.audio = new Audio();
  }

  load(url: string): void {
    if (this.isPlaying) this.stop();
    this.audio.src = url;
    this.audio.load();
  }

  play(): Promise<void> {
    this.isPlaying = true;
    return this.audio.play();
  }

  pause(): void {
    this.isPlaying = false;
    this.audio.pause();
  }

  stop(): void {
    this.isPlaying = false;
    this.audio.pause();
    this.audio.src = '';
  }

  onTimeUpdate(
    callback: (currentTime: number, duration: number) => void,
  ): void {
    this.audio.addEventListener('timeupdate', () => {
      callback(this.audio.currentTime, this.audio.duration);
    });
  }

  isCurrentlyPlaying(): boolean {
    return this.isPlaying;
  }
}
