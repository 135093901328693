import { HttpInterceptorFn } from '@angular/common/http';
import { inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { catchError, throwError } from 'rxjs';
import { GoogleAdsService } from '../../shared/services/google-ads.service';

export const trackingInterceptor: HttpInterceptorFn = (request, next) => {
  const platformId = inject(PLATFORM_ID);
  const googleAdsService = inject(GoogleAdsService);
  const isBrowser = isPlatformBrowser(platformId);
  if (isBrowser && request.method === 'POST') {
    const gclid = googleAdsService.getTag('gclid');
    const wbraid = googleAdsService.getTag('wbraid');
    const gbraid = googleAdsService.getTag('gbraid');
    const modifiedBody = {
      ...(request.body as object),
      gclid: gclid,
      wbraid: wbraid,
      gbraid: gbraid,
    };
    const modifiedRequest = request.clone({
      body: modifiedBody,
    });
    return next(modifiedRequest).pipe(
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }
  return next(request);
};
