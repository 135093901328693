import {Injectable} from '@angular/core';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GTMService {

  constructor(private gtmService: GoogleTagManagerService,
  ) {
  }


  customEvents(eventName: string, eventKey: any, eventData: any) {
    let gtmTag: any = {
      event: eventName
    };
    gtmTag[eventKey] = eventData
    if (environment.production) {
      this.gtmService.pushTag(gtmTag);
    }
  }

  exitRegistrationForm(eventData: object) {
    if (environment.production) {
      this.customEvents('current flow - exit button', 'gtm', eventData)
    }
  }

}
