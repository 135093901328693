import {DOCUMENT} from '@angular/common';
import {Injectable, Inject, Injector} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {metaTag} from "../constants/meta-tag";

@Injectable({
  providedIn: 'root'
})
export class SEOService {
  constructor(injector: Injector,
              private meta: Meta,
              private titleService: Title,
              @Inject(DOCUMENT) private dom: any) {
  }

  updateCanonicalUrl(url: string, isRtl: boolean, isFromCMS?: boolean, customUrl?: string) {
    let baseUrl = `https://algooru.com${isRtl ? "" : "/en"}`
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical')
    if (isFromCMS) {
      element.setAttribute('href', url)
      this.updateTagsByProperty(metaTag.ogURL, url)
    } else {
      element.setAttribute('href', baseUrl + url)
      this.updateTagsByProperty(metaTag.ogURL, baseUrl + url)
    }
    this.updateAlternateUrl(url, isRtl, isFromCMS ?? false, customUrl)

  }

  updateAlternateUrl(url: string, isRtl: boolean, isFromCMS: boolean, customUrl?: string) {
    let baseUrl = "https://algooru.com";

    const head = this.dom.getElementsByTagName('head')[0];

    // Create the first alternate link for the default language (English)
    let element: HTMLLinkElement = this.dom.querySelector(`link[rel='alternate'][hreflang='en']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'alternate');
    element.setAttribute('hreflang', 'en');
    if (customUrl) {
      element.setAttribute('href', baseUrl + '/en' + customUrl);
    } else {
      element.setAttribute('href', isFromCMS ? url : baseUrl + '/en' + url);
    }

    // Create the second alternate link for the RTL language (Arabic)
    let rtlElement: HTMLLinkElement = this.dom.querySelector(`link[rel='alternate'][hreflang='ar']`) || null;
    if (rtlElement == null) {
      rtlElement = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(rtlElement);
    }
    rtlElement.setAttribute('rel', 'alternate');
    rtlElement.setAttribute('hreflang', 'ar');
    if (customUrl) {
      rtlElement.setAttribute('href', baseUrl + customUrl);
    } else {
      rtlElement.setAttribute('href', isFromCMS ? url : baseUrl + url);
    }
  }

  updateStructuredData(data: any) {
    if (data) {
      let script = this.dom.createElement("script");
      let head = this.dom.getElementsByTagName('head')[0];
      script.type = "application/ld+json";
      script.textContent = JSON.stringify(data);
      head.appendChild(script);
    }
  }

  setTitle(title: string) {
    if (title){
      this.titleService.setTitle(title)
    }
  }
  setTitleV2(title: string,RTL:boolean){
    let base = RTL?'القورو':'AlGooru'
    if (title){
        this.titleService.setTitle(base+' - '+title)
    }else{
      if (RTL){
        this.titleService.setTitle("القورو - احجز مع أفضل مدرسين خصوصيين بالمملك")
      }else{
        this.titleService.setTitle( 'AlGooru - Get the best private tutors in Saudi Arabia')
      }
    }
  }

  updateTagsByName(key: string, value: string) {
    this.meta.updateTag(
      {name: key, content: value}
    );
  }

  updateTagsByProperty(key: string, value: string) {
    this.meta.updateTag(
      {property: key, content: value}
    );
  }

  setEnglishMetTag() {
    this.updateTagsByName(metaTag.description, 'AlGooru is a tutoring marketplace that connects students with qualified private tutors in Saudi and the Middle East. We’ll match you with the tutor that fits your educational need.')
    this.updateTagsByProperty(metaTag.ogLocale, 'en_EN')
    this.updateTagsByProperty(metaTag.ogTitle, 'AlGooru - Get the best private tutors in Saudi Arabia')
    this.updateTagsByProperty(metaTag.ogDescription, 'AlGooru is a tutoring marketplace that connects students with qualified private tutors in Saudi and the Middle East. We’ll match you with the tutor that fits your educational need.')
    this.updateTagsByProperty(metaTag.ogImage, '/assets/og-image.png')
    this.updateTagsByName(metaTag.twitterTitle, 'AlGooru - Get the best private tutors in Saudi Arabia')
    this.updateTagsByName(metaTag.twitterDescription, 'AlGooru is a tutoring marketplace that connects students with qualified private tutors in Saudi and the Middle East. We’ll match you with the tutor that fits your educational need.')
    this.dom.documentElement.lang = 'en';
  }

  setArabicMetTag() {
    this.updateTagsByName(metaTag.description, "القورو منصّة تعليميّة تربط طلّاب المدارس والجامعات مع مدرس خصوصي من أفضل المعلّمين الخصوصيّين في المملكة والشرق الأوسط. عندنا أخصّائيين تعليم يوصلونك مع معلم لأي مادة تختارها!")
    this.updateTagsByProperty(metaTag.ogLocale, 'ar_AR')
    this.updateTagsByProperty(metaTag.ogTitle, "القورو - احجز مع أفضل مدرسين خصوصيين بالمملكة")
    this.updateTagsByProperty(metaTag.ogDescription, "القورو منصّة تعليميّة تربط طلّاب المدارس والجامعات مع مدرس خصوصي من أفضل المعلّمين الخصوصيّين في المملكة والشرق الأوسط. عندنا أخصّائيين تعليم يوصلونك مع معلم لأي مادة تختارها!")
    this.updateTagsByProperty(metaTag.ogImage, '/assets/og-image.png')
    this.updateTagsByName(metaTag.twitterTitle, "القورو - احجز مع أفضل مدرسين خصوصيين بالمملكة")
    this.updateTagsByName(metaTag.twitterDescription, "القورو منصّة تعليميّة تربط طلّاب المدارس والجامعات مع مدرس خصوصي من أفضل المعلّمين الخصوصيّين في المملكة والشرق الأوسط. عندنا أخصّائيين تعليم يوصلونك مع معلم لأي مادة تختارها!")
    this.dom.documentElement.lang = 'ar';
  }

  setCustomMetTag(title?: string, prefix = true, isRtl?: boolean, description?: string, imageUrl?: string) {
    let lang = isRtl ? 'ar_AR' : 'en_EN'
    this.updateTagsByProperty(metaTag.ogLocale, lang)
    if (title) {
      if (prefix) {
        this.setTitle((isRtl ? 'القورو - ' : 'AlGooru - ') + title)
      } else {
        this.setTitle(title)
      }
      this.updateTagsByProperty(metaTag.ogTitle, title)
      this.updateTagsByName(metaTag.twitterTitle, title)
    }
    if (description) {
      this.updateTagsByName(metaTag.description, description)
      this.updateTagsByProperty(metaTag.ogDescription, description)
      this.updateTagsByName(metaTag.twitterDescription, description)
    }
    if (imageUrl) {
      this.updateTagsByProperty(metaTag.ogImage, imageUrl)
    }
  }
}
