import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {isPlatformBrowser} from "@angular/common";
import {screen} from "../misc/screen";
import {SessionType} from "@enums";

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private _selectedScreen: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _subject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _isOtherSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  private _educationLevel: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _activeClass: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _activeLevel: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _leadType: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _activePurposes: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private _dealId: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _coupon: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  activePurposes:any = []
  sessionType: SessionType = SessionType.ONLINE
  screen= screen
  get isBrowser() {
    return isPlatformBrowser(this.platformId)
  }
  get selectedScreen(){
    return this._selectedScreen.getValue()
  }
  removeAllState(){
    this.setSubject(null)
    this.setIsOtherSubject(false)
    this.setEducationLevel(null)
    this.setActiveClass(null)
    this.setActiveLevel(null)
    this.setLeadType(null)
    this.setActivePurposesEnd([])
    localStorage.removeItem('activePurposes')
    localStorage.removeItem('subject')
    localStorage.removeItem('isOtherSubject')
    localStorage.removeItem('educationLevel')
    localStorage.removeItem('activeClass')
    localStorage.removeItem('activeLevel')
    localStorage.removeItem('leadType')
    this.sessionType =  SessionType.ONLINE
    this.setSelectedScreen(screen.subject)
  }
  setEventValue(values:any){
    if (this.isBrowser) {
      localStorage.setItem('eventsValues', JSON.stringify(values))
    }
  }
  getEventValue(){
    if (this.isBrowser) {
      let values:any  = localStorage.getItem('eventsValues')
     return JSON.parse(values)
    }
  }
  setSelectedScreen(value:any){
    switch (value) {
      case screen.subject:{
        this.setSubject(null)
        this.setIsOtherSubject(false)
        this.setEducationLevel(null)
        this.setActiveClass(null)
        this.setLeadType(null)
        this.setActiveLevel(null)
        this.setActivePurposesEnd([])
        localStorage.removeItem('activePurposes')
        localStorage.removeItem('subject')
        localStorage.removeItem('isOtherSubject')
        localStorage.removeItem('educationLevel')
        localStorage.removeItem('activeClass')
        localStorage.removeItem('leadType')
        localStorage.removeItem('activeLevel')
        this.sessionType =  SessionType.ONLINE
        this.activePurposes = []
        break
      }
      case screen.level:{
        this.setEducationLevel(null)
        this.setActiveClass(null)
        this.setActiveLevel(null)
        this.setLeadType(null)
        this.setActivePurposesEnd([])
        localStorage.removeItem('activePurposes')
        localStorage.removeItem('educationLevel')
        localStorage.removeItem('activeClass')
        localStorage.removeItem('activeLevel')
        localStorage.removeItem('leadType')
        this.activePurposes = []
        break
      }
      case screen.tutoringGoals:{
        this.setLeadType(null)
        this.setActivePurposesEnd([])
        localStorage.removeItem('activePurposes')
        localStorage.removeItem('leadType')
        this.activePurposes = []
        break
      }
      case screen.info:{
        this.setLeadType(null)
        localStorage.removeItem('leadType')
        break
      }
      case screen.packages:{
        this.sessionType =  SessionType.ONLINE
        break
      }

    }
    if (this.isBrowser){
      this._selectedScreen.next(value)
      localStorage.setItem('selectedScreen',value)
    }
  }
 initScreen():any{
    if (this.isBrowser){
      let screen:any =localStorage.getItem('selectedScreen')|| this.screen.subject
      return screen>=0?screen : this.screen.subject
    }
   }
  /////////////
  get subject(){
    if (this.isBrowser){
      let objects:any = localStorage.getItem('subject')
      return JSON.parse(objects)|| this._subject.getValue()
    }
   }
  setSubject(value:any){
    if (this.isBrowser){
      this._subject.next(value)
      localStorage.setItem('subject', JSON.stringify(value))
    }
  }
  ////////////////
  get coupon(){
    if (this.isBrowser){
      let objects:any = localStorage.getItem('coupon')
      return JSON.parse(objects)|| this._coupon.getValue()
    }
  }
  setCoupon(value:any){
    if (this.isBrowser){
      this._coupon.next(value)
      localStorage.setItem('coupon', JSON.stringify(value))
    }
  }
  ////////////////
  get dealId(){
    if (this.isBrowser){
      let objects:any = localStorage.getItem('dealId')
      return JSON.parse(objects)|| this._dealId.getValue()
    }
  }
  setDealId(value:any){
    if (this.isBrowser){
      this._dealId.next(value)
      localStorage.setItem('dealId', JSON.stringify(value))
    }
  }
  ////////////////
  get activePurposesEnd(){
    if (this.isBrowser){
      let activePurposes:any = localStorage.getItem('activePurposes')
      return JSON.parse(activePurposes)|| this._activePurposes.getValue() || []
    }
  }
  setActivePurposesEnd(value:any){
    if (this.isBrowser){
      this._activePurposes.next(value)
      localStorage.setItem('activePurposes',JSON.stringify(value))
    }
  }
  ///////////
  get isOtherSubject(){
    if (this.isBrowser){
      let isOtherSubject = localStorage.getItem('isOtherSubject') == 'true'
      return isOtherSubject|| this._isOtherSubject.getValue()
    }
   }
  setIsOtherSubject(value:any){
    if (this.isBrowser){
      this._isOtherSubject.next(value)
      localStorage.setItem('isOtherSubject',value)
    }
  }

  ////////////////
  get educationLevel(){
     if (this.isBrowser){
      return (localStorage.getItem('educationLevel')|| this._educationLevel.getValue())
    }
  }
  setEducationLevel(value:any){
    if (this.isBrowser){
      this._educationLevel.next(value)
      localStorage.setItem('educationLevel',value)
    }
  }
  ////////////////
  get activeClass(){
     if (this.isBrowser){
      return localStorage.getItem('activeClass')|| this._activeClass.getValue()
    }
  }
  setActiveClass(value:any){
     if (this.isBrowser){
      this._activeClass.next(value)
      localStorage.setItem('activeClass',value)
    }
  }
  ////////////////
  get activeLevel(){
    if (this.isBrowser){
      return localStorage.getItem('activeLevel')|| this._activeLevel.getValue()
    }
  }
  setActiveLevel(value:any){
    if (this.isBrowser){
      this._activeLevel.next(value)
      localStorage.setItem('activeLevel',value)
    }
  }
  ////////////////
  get leadType(){
     if (this.isBrowser){
      return localStorage.getItem('leadType')|| this._leadType.getValue()
    }
  }
  setLeadType(value:any){
    if (this.isBrowser){
      this._leadType.next(value)
      localStorage.setItem('leadType',value)
    }
  }
  ////////////////

  constructor( @Inject(PLATFORM_ID) private platformId: string) { }
}
