import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {SegmentService} from "./segment.service";
import {UtilityService} from "./utility.service";

@Injectable({
  providedIn: 'root'
})
export class NELCService  {
  _showCertificate: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  showCertificate(){
    this.segmentService.track('NELC clicked', {
      'pageName': this.utility.seqmentPageName
    })
      this._showCertificate.next(true)

  }
  constructor(private segmentService:SegmentService, private utility:UtilityService) { }
}
