export const simpleFooterPages = [
  'prospect',
  'step',
  'curriculums',
  'thank-you',
  'signup',
  'registration-flow',
];

export const notSimpleFooter = [
  'registration-flow/result',
  'prospect/result',
  'trial-session/thank-you',
  'curriculums',
];

export const withoutTopFooterSection = [
  'learn/ataa',
];

export const withoutFooterPages = [ 'learn/ataa', '/tutor/', 'qualification-certificate'];
