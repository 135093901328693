import { SubjectModel, TutorModel } from '@models';
import { getValueByLang } from './index';
import { lang } from '@enums';
import { FooterListInterface } from '@common-dto';

export const convertToFooterList = <T extends SubjectModel | TutorModel>(
  items: T[],
  currLang: lang,
  key: string,
  translateCallback: (key: string) => any,
): FooterListInterface[] => {
  return [
    {
      mainItem: true,
      title: translateCallback(key) as string,
    },
    ...items.map((item) => ({
      ...item,
      title: getValueByLang({
        ar: item.ar_title,
        en: item.en_title,
        currentLanguage: currLang,
      }) as string,
      mainItem: false,
    })),
  ];
};
