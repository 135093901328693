import * as pako from 'pako';

export const decompressImage = (imageData: Uint8Array): string => {
  try {
    const decompressed = pako.inflate(imageData, { to: 'string' });
    return `data:image/jpeg;base64,${decompressed}`;
  } catch (err) {
    console.error('Failed to decompress image:', err);
    return '';
  }
};
