import { Mapper } from '@utils';
import {PaymentLinkModel} from '@models';
import {Injectable} from '@angular/core';
import {PaymentLinkEntity} from "../entities";

@Injectable({
  providedIn: 'root',
})
export class PaymentLinkRepoMapper extends Mapper<
  PaymentLinkEntity,
  PaymentLinkModel
> {

  mapFrom(param: PaymentLinkEntity): PaymentLinkModel {
      return {
        link: param.paymentLink,
        active: param.isActive,
        dealId: param.dealId,
        packageId: param.packageId
      }
  }
}
