import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { dir } from '@enums';

@Injectable({ providedIn: 'root' })
export class InitializeDirectionService {
  translateService = inject(TranslateService);
  document = inject(DOCUMENT);

  initializeDirection() {
    this.translateService.stream('DIR').subscribe((dir) => {
      this.directionChanged(dir);
    });
  }

  private directionChanged(direction: string): void {
    const htmlTag = this.document.getElementsByTagName(
      'html',
    )[0] as HTMLHtmlElement;
    htmlTag.dir = direction === dir.RTL ? dir.RTL : dir.LTR;
  }
}
