import { map } from 'rxjs/operators';
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { WebsiteImplRepoMapper } from './mappers/website-repo.mapper';
import {PackageModel, PaymentLinkModel, PaymentLinkPayloadModel, WebsiteDataModel} from '@models';
import {PackageEntity, PaymentLinkEntity, WebsiteDataEntity} from './entities';
import { environment } from '../../../environments/environment';
import { CommonRepository } from '@repos';
import {TranslateFacadeService} from "@services";
import {SessionType, WebPageEnum} from "@enums";
import {PackageImplRepoMapper} from "./mappers/package-repo.mapper";
import {PaymentLinkRepoMapper} from "./mappers/payment-link-repo.mapper";
import {pk} from "./static/packages";

@Injectable({
  providedIn: 'root',
})
export class CommonImplRepository extends CommonRepository {
  websiteDataMapper = new WebsiteImplRepoMapper();
  packageDataMapper = new PackageImplRepoMapper();
  paymentRepoMapper = new PaymentLinkRepoMapper();

  private readonly translateFacade = inject(TranslateFacadeService);

  constructor(private http: HttpClient) {
    super();
  }
  pk:any
  getWebsiteData(): Observable<WebsiteDataModel> {
    return this.http
      .get<WebsiteDataEntity>(
        `${environment.strapiUrl}/website?locale=${this.translateFacade.currLang}`,
        { params: { noLoading: 'true' } },
      )
      .pipe(map(this.websiteDataMapper.mapFrom));
  }

  override getPackages(params: { session_type: SessionType; web_page: WebPageEnum }): Observable<PackageModel[]> {
    // return this.http
    //   .get<{ data: PackageEntity[] }>(
    //     `${environment.apiUrl}/TMM/packages?locale=${this.translateFacade.currLang}&session_type=${params.session_type}`,
    //     { params: { noLoading: 'true', ...params } },
    //   )
    //   .pipe(map((data) => this.packageDataMapper.mapFrom(data.data)));
    this.pk = pk[params.session_type];
    return of(this.pk).pipe(map((data) => this.packageDataMapper.mapFrom(this.pk.data)))
  }

  override generatePaymentLink(payload:PaymentLinkPayloadModel): Observable<PaymentLinkModel> {
    return this.http.post<{data: PaymentLinkEntity}>(`${environment.apiUrl}/TMM/generatePaymentLink`, payload, {params: {noLoading: true}})
      .pipe(map(data => this.paymentRepoMapper.mapFrom(data.data)))
  }
}
