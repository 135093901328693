export enum clickId {
   'Maarouf link' = 0,
   'CNBC video' = 1,
   'Bloomberg video' = 2,
   'Arabiyya video' = 3,
   'Wamda link' = 4,
   'Whatsapp Number' = 5,
   'algooru email' = 6,
   'linkedin link' = 7,
   'twitter link' = 8,
   'instagram link' = 9,
   'tiktok link' = 10,
   'Google Play link' = 11,
   'App Store link' = 12,
}