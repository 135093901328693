import {Routes} from '@angular/router';

export const marketRoutes: Routes = [
  {
    path: 'registration-flow',
    loadChildren: () => import('./components/new-signup-flow/new-signup-flow-app.module').then(m => m.NewSignupFlowAppModule),
  },
  {
    path: ':slug', loadComponent: () => import('./market.component').then(m => m.MarketComponent),
  },

];
