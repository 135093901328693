import { Mapper } from '@utils';
import { FeaturedSubjectModel } from '@models';
import { FeaturedSubjectEntity } from '../entities';

export class FeaturedSubjectRepoMapper extends Mapper<
  FeaturedSubjectEntity,
  FeaturedSubjectModel[]
> {
  mapFrom(param: FeaturedSubjectEntity): FeaturedSubjectModel[] {
    const { data } = param;

    return data.map((subject) => ({
      id: subject.id,
      name_en: subject.name_en,
      name_ar: subject.name_ar,
      tutors: subject.featured_tutors.map((tutor) => ({
        id: tutor.id,
        name_ar: tutor.name_ar,
        name_en: tutor.name_en,
        bio_en: tutor.bio_en,
        bio_ar: tutor.bio_ar,
        slug:tutor.slug,
        tutor_testimonials_rating_avg: tutor.tutor_testimonials_rating_avg,
        image: tutor.tutor_profile?.filePath,
        completed_sessions: tutor.completed_sessions_count,
        subject_en: subject.name_en,
        subject_ar: subject.name_ar,
      })),
    }));
  }
}
