import { Mapper } from '@utils';
import { WebsiteDataEntity } from '../entities';
import { WebsiteDataModel } from '@models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WebsiteImplRepoMapper extends Mapper<
  WebsiteDataEntity,
  WebsiteDataModel
> {
  mapFrom(param: WebsiteDataEntity): any {
    const { subject, level, freeSessionsBar ,assessments} = param;

    return {
      subjects: subject.length > 0 ? subject.map((subject) => ({
        ar_title: subject.Title,
        en_title: subject.TitleEn,
        slug: subject.slug,
        link: `learn/${subject.slug}`,
        parent: 'learn',
      })) : [],
      sessionBar: {
        ...freeSessionsBar,
        code: freeSessionsBar?.promocode,
      },
      levels: level.map((level) => ({
        id: level.id,
        level_ar: level.Level,
        level_en: level.LevelEn,
        educational_resource_classes: level.educational_resource_classes.map(
          (erc) => ({
            name_ar: erc.Name,
            name_en: erc.NameEn,
            id: erc.id,
            slug: erc.slug,
          }),
        ),
      })),
      assessments: assessments?.length > 0 ? assessments.map((assessments) => ({
        ar_title: assessments.footerTitle,
        en_title: assessments.footerTitle,
        slug: assessments.slug,
        link: `assessment/${assessments.slug}`,
        parent: 'assessment',
      })) : [],
    };
  }
}
