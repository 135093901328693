import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleAdsService {

  constructor() { }
  readTags(){
    this.addGclid()
    this.addTag('wbraid')
    this.addTag('gbraid')
  }
  getTag(tag:string){
    let tagValue: any
    tagValue = localStorage.getItem(tag)
    return JSON.parse(tagValue)?.value
  }

  getParam(p: string): string | null {
    const match = RegExp('[?&]' + p + '=([^&]*)').exec(decodeURIComponent(window.location.search));
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }

  getExpiryRecord(value: string): { value: string; expiryDate: number } {
    const expiryPeriod = 90 * 24 * 60 * 60 * 1000; // 90 day expiry in milliseconds
    const expiryDate = new Date().getTime() + expiryPeriod;
    return {
      value: value,
      expiryDate: expiryDate,
    };
  }

  addGclid(): void {
    const gclidParam = this.getParam('gclid');
    let gclidRecord = null;
    const gclsrcParam = this.getParam('gclsrc');
    const isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1;
    if (gclidParam && isGclsrcValid) {
      gclidRecord = this.getExpiryRecord(gclidParam);
      localStorage.setItem('gclid', JSON.stringify(gclidRecord));
    }
    const gclid = gclidRecord || JSON.parse(localStorage.getItem('gclid')||'{}');
    const isGclidValid = gclid && new Date().getTime() < gclid.expiryDate;
    if (!isGclidValid) {
      localStorage.removeItem('gclid');
    }
  }
  addTag(tag:string): void {
    const gclidParam = this.getParam(tag);
    let gclidRecord = null;
     if (gclidParam) {
      gclidRecord = this.getExpiryRecord(gclidParam);
      localStorage.setItem(tag, JSON.stringify(gclidRecord));
    }
    const gclid = gclidRecord || JSON.parse(localStorage.getItem(tag)|| '{}');
    const isGclidValid = gclid && new Date().getTime() < gclid.expiryDate;
    if (!isGclidValid) {
      localStorage.removeItem(tag);
    }
  }
}
