import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(@Inject(PLATFORM_ID) private platformId: string) {}

  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  getStorageItem(item: string) {
    return sessionStorage.getItem(item);
  }

  setReferral(referral: string = 'Main') {
    this.isBrowser && sessionStorage.setItem('Referral', referral);
  }

  getReferralType(type: string, include: boolean = false) {
    if (!this.isBrowser) return undefined;
    if (include) return sessionStorage.getItem('Referral')?.includes(type);
    return sessionStorage.getItem('Referral') === type;
  }
}
