import {Routes} from '@angular/router';

export const assessmentRoutes: Routes = [
  {
    path: ':slug', loadComponent: () => import('./assessment.component').then(m => m.AssessmentComponent),
    children: [
      {path: '', loadComponent: () => import('./pages/home/home.component').then(m => m.HomeComponent)},
      {path: 'test', loadComponent: () => import('./pages/test/test.component').then(m => m.TestComponent)},
      {path: 'result', loadComponent: () => import('./pages/result/result.component').then(m => m.ResultComponent)},
    ]
  },

];
