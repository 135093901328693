import { map } from 'rxjs/operators';
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HomePageModel } from '@models';
import { HomeRepository } from '@repos';
import { environment } from '../../../environments/environment';
import { TranslateFacadeService } from '@services';
import { HomeImplRepoMapper } from './mappers/home-repo.mapper';
import { HomePageEntity } from './entities';

@Injectable({
  providedIn: 'root',
})
export class HomeImplRepository extends HomeRepository {
  homeMapper = new HomeImplRepoMapper();
  private readonly translateFacade = inject(TranslateFacadeService);

  constructor(private http: HttpClient) {
    super();
  }

  getHomePage(preview: boolean): Observable<HomePageModel> {
    return this.http
      .get<HomePageEntity>(
        preview
          ? `${environment.strapiUrl}/home-page-v1?populate=deep&publicationState=preview&locale=${this.translateFacade.currLang}`
          : `${environment.strapiUrl}/home-page-v1?populate=deep&locale=${this.translateFacade.currLang}`,
      )
      .pipe(map(this.homeMapper.mapFrom));
  }
}
