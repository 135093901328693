import {ChangeDetectorRef, Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading404: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get isLoading(){
    return this.loading.value || this.loading404.value
  }
  constructor() { }
  setLoading(value: boolean) {
    this.loading.next(value);
  }
  set404Loading(value: boolean) {
    this.loading404.next(value);
  }
}
