import { map } from 'rxjs/operators';
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FeaturedSubjectModel } from '@models';
import { FeaturedSubjectEntity } from './entities';
import { environment } from '../../../environments/environment';
import { FeaturedSubjectRepoMapper } from './mappers/featured-subject-repo.mapper';
import { FeaturedSubjectRepository } from '@repos';
import { TranslateFacadeService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class FeaturedSubjectImplRepository extends FeaturedSubjectRepository {
  featuredSubjectMapper = new FeaturedSubjectRepoMapper();
  private readonly translateFacade = inject(TranslateFacadeService);

  constructor(private http: HttpClient) {
    super();
  }

  getFeaturedSubjects(): Observable<FeaturedSubjectModel[]> {
    return this.http
      .get<FeaturedSubjectEntity>(`${environment.apiUrl}/featured/subjects`, {
        headers: {
          'Accept-Language': this.translateFacade.currLang,
        },
        params: { noLoading: 'true' },
      })
      .pipe(map(this.featuredSubjectMapper.mapFrom));
  }
}
