import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  selectedPage: string | undefined;

  constructor(
    public router: Router,
    private localize: LocalizeRouterService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.selectedPage = event.url;
      }
    });
  }

  public navigateWithTranslation(path: string) {
    let translatedPath = this.localize.translateRoute([path]);
    this.router.navigate([translatedPath]).then();
  }

  public isSelectedPage(key: string): boolean {
    const decodedPage = decodeURIComponent(this.selectedPage!);
    return !!(
      decodedPage.includes(key) ||
      this.selectedPage?.includes(`${key}/`) ||
      this.selectedPage?.includes(`/${key}`)
    );
  }
}
