import { HttpClient } from '@angular/common/http';
import { MultiTranslateHttpLoader } from '@utils';

export function multiTranslateLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, {
    resources: [
      { prefix: './assets/i18n/', suffix: '.json' },
      {
        prefix: './assets/i18n/featured_subjects/',
        suffix: '.json',
      },
      {
        prefix: './assets/i18n/self_checkout/',
        suffix: '.json',
      },
      {
        prefix: './assets/i18n/packages/',
        suffix: '.json',
      },
      {
        prefix: './assets/i18n/signup-flow/',
        suffix: '.json',
      },
      {
        prefix: './assets/i18n/tutor/',
        suffix: '.json',
      },
      {
        prefix: './assets/i18n/lead_magnet/',
        suffix: '.json',
      },
    ],
  });
}
